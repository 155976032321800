import { defineStore } from "pinia";
import {
  Routes,
  HEADER_X_TOKEN,
  HEADER_TEAM_API_KEY,
  HEADER_MEMBER_API_KEY,
  DEFAULT_PAGE_SIZE,
  apiServerUrl,
} from "~/constants";

const state = {
  currentTags: {},
  tagsCount: 0,
  tags: [],
  tagContactCount: [],
  uploadedFileId: null,
  isUploading: false,
  uploadingPercent: 0,
  error: false,
  uploaded: false,
  matchFields: [],
  tagContacts: [],
  tagAccessRequest: {
    isShareable: false,
    isEditable: false,
  },
};

export const useAudienceTagStore = defineStore("audienceTagStore", {
  state: () => state,
  persist: {
    storage: persistedState.sessionStorage,
  },
  getters: {
    getState: (state) => state,
    getAllTag: (state) => state.tags,
    getTagContactCount: (state) => state.tagContactCount,
    getTagContacts: (state) => state.tagContacts,
  },
  actions: {
    setResource({ resource, value }) {
      this[resource] = value;
    },
    pushItem({ resource, value }) {
      if (!Array.isArray(this[resource])) {
        this[resource] = [];
      }
      this[resource].push(value);
    },
    setItem({ item, id, resource }) {
      if (!this[resource]) {
        this[resource] = {};
      }
      this[resource][id] = item;
    },
    setItemWithIdField({ item, id, resource }) {
      if (!this[resource]) {
        this[resource] = {};
      }
      let itemIndex = this[resource].findIndex(
        (currItem) => currItem.id === id
      );
      this[resource][itemIndex] = item;
    },

    refreshTagData() {
      this.setResource({ resource: "tags", value: [] });
      this.setResource({ resource: "tagsCount", value: 0 });
      this.setResource({ resource: "tagContactCount", value: {} });
      this.setResource({ resource: "tagContacts", value: [] });
    },

    fetchAllTags({ offset, limit, order, entity, search }) {
      const store = useGlobalStore();

      return new Promise((resolve, reject) => {
        let xoffset = offset ?? 0;
        let xlimit = limit ?? DEFAULT_PAGE_SIZE;
        let xentity = entity ?? "";
        let xorder = order ?? "";

        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/tag`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            offset: xoffset,
            limit: xlimit,
            search: search,
            entity: xentity,
            order: xorder,
          },
          key: 'tagSearch',
         
        })
          .then((resp) => {
            const tags = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get tags error : ", error);
              reject(error);
            } else {
              console.log("tags data : ", tags);
              this.setResource({
                resource: "tags",
                value: tags,
              });
              resolve(tags);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchAllTagCount({ search } = {}) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        console.log("apiKey : ", apiKey);
        useFetch(`${apiServerUrl()}team/tag/count`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            search: search,
          },
        })
          .then((resp) => {
            const tagsCount = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get tagsCount error : ", error);
              reject(error);
            } else {
              console.log("tagsCount data : ", tagsCount);
              this.setResource({
                resource: "tagsCount",
                value: tagsCount.count,
              });
              resolve(tagsCount);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createTag({ tagName }) {
      const store = useGlobalStore();
      const body = {
        name: tagName,
      };
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/tag`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: body,
        })
          .then((resp) => {
            const tag = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get tags error : ", error);
              reject(error);
            } else {
              console.log("tags data : ", tag);
              // this.tags.push(tag);
              resolve(tag);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchTagContactCount({ id, search }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(apiServerUrl() + "team/tag/" + id + "/contact/count", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            search: search,
          },
        })
          .then((resp) => {
            const tagCount = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("Tag contact count error : ", error);
              reject(error);
            } else {
              console.log("Tag contact count : ", tagCount.count);
              this.setItem({
                resource: "tagContactCount",
                id: id,
                item: tagCount.count,
              });
              resolve(tagCount.count);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    fetchTagsContactCount({ tids }) {
      const store = useGlobalStore();
      const lStore = useAudienceListStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const data = {
          ids: tids,
        };

        useFetch(apiServerUrl() + "team/tag/contact/counts", {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(data),
        })
          .then((resp) => {
            const tagCountArray = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("Tag contact counts error : ", error);
              reject(error);
            } else {
              tagCountArray.forEach(element => {
                this.setItem({
                  resource: "tagContactCount",
                  id: element.entityId,
                  item: element.contactCount,
                });
              });

              resolve(tagCountArray);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    exportTagById({ id }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/tag/` + id + "/export", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((resp) => {
            const exportRes = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("Tag export error : ", error);
              reject(error);
            } else {
              console.log("exportRes : ", exportRes);
              resolve(exportRes);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    updateTag({ name, id }) {
      const store = useGlobalStore();
      const body = {
        name: name,
      };
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/tag/` + id, {
          method: "PUT",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: body,
        })
          .then((resp) => {
            const res = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("Tag update error : ", error);
              reject(error);
            } else {
              console.log("tag update response : ", res);
              resolve(res);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    updateShareEditStatusForTag({ id }) {
      const store = useGlobalStore();

      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/tag/` + id + "/franchise", {
          method: "PUT",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: this.tagAccessRequest,
        })
          .then((resp) => {
            const res = resp.data.value;
            const error = resp.error.value;

            if (error) {
              reject(error);
            } else {
              this.setItemWithIdField({
                resource: "tags",
                id: id,
                item: res,
              });
              resolve(res);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getTagById(id) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

        useFetch(`${apiServerUrl()}team/tag/` + id, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((resp) => {
            const tag = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("Tag get error : ", error);
              reject(error);
            } else {
              console.log("tag : ", tag);
              this.setItem({
                resource: "currentTags",
                id: id,
                item: tag,
              });
              resolve(tag);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    deleteTagById({ id, deleteContacts }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const body = {
          deleteContacts: deleteContacts,
        };

        useFetch(apiServerUrl() + "team/tag/" + id, {
          method: "DELETE",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: body,
        })
          .then((resp) => {
            const deleteRes = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("Tag delete error : ", error);
              reject(error);
            } else {
              console.log("deleteRes : ", deleteRes);
              resolve(deleteRes);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    uploadTagCSV(e) {
      const formData = new FormData();
      formData.append("fileinput", e);
      const store = useGlobalStore();
      const audienceTagStore = useAudienceTagStore();

      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        const xhr = new XMLHttpRequest();

        xhr.open("POST", `${apiServerUrl()}team/list/import`, true);

        // Set the API key header
        xhr.setRequestHeader(HEADER_TEAM_API_KEY, apiKey);
        xhr.setRequestHeader(HEADER_MEMBER_API_KEY, memberApiKey);

        // Monitor upload progress
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            console.log(event, "event");
            const percentComplete = (event.loaded / event.total) * 100;
            console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
            // this.uploadingPercent = percentComplete.toFixed(2);
            audienceTagStore.setResource({
              resource: "uploadingPercent",
              value: percentComplete.toFixed(2),
            });
            // Update your UI with the progress percentage if needed
          }
        };

        // Handle successful upload
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const response = JSON.parse(xhr.responseText);
              // const data = response.data?.value;
              // const error = response.error?.value;

              // console.log(data, error, "data, error", response);

              if (!response) {
                console.log("error ::: ");
                reject({ message: "Error while uplaoding" });
              } else {
                audienceTagStore.setResource({
                  resource: "uploadedFileId",
                  value: response.id,
                });
                resolve(response);
              }
            } catch (error) {
              console.log("Parsing error -> ", error);
              reject(error);
            }
          } else {
            console.log("error -> ", xhr.statusText);
            reject(xhr.statusText);
          }
        };

        // Handle network error
        xhr.onerror = function () {
          console.log("Network error -> ", xhr.statusText);
          reject(xhr.statusText);
        };

        // Send the form data
        xhr.send(formData);
      });
    },
    mapContactsToTag(id) {
      const store = useGlobalStore();
      const apiKey = store.$state?.currentTeam?.apiKey ?? "";
      const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
      const body = {
        fileId: this.uploadedFileId,
        contactProperties: this.matchFields,
      };

      return new Promise((resolve, reject) => {
        useFetch(`${apiServerUrl()}team/tag/` + id + `/mapping`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: body,
        })
          .then((resp) => {
            const data = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("error :: ", error);
              reject(error);
            } else {
              console.log("mapContactsToTag : ", data);
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    manualMapContactsToTag({id, text}) {
      const store = useGlobalStore();
      const apiKey = store.$state?.currentTeam?.apiKey ?? "";
      const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
      const body = {
        data: text,
      };

      return new Promise((resolve, reject) => {
        useFetch(`${apiServerUrl()}team/tag/` + id + `/mapping/manual`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(body),
        })
          .then((resp) => {
            const data = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("error manualMapContactsToTag :: ", error);
              reject(error);
            } else {
              resolve(data);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
    fetchAllTagContacts({ offset, limit, contactType, search, id }) {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        let xoffset = offset ?? 0;
        let xlimit = limit ?? DEFAULT_PAGE_SIZE;
        let xcontactType = contactType ?? "";

        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/tag/` + id + "/contact", {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          params: {
            offset: xoffset,
            limit: xlimit,
            search: search,
            contactType: xcontactType,
          },
        })
          .then((resp) => {
            const contacts = resp.data.value;
            const error = resp.error.value;
            if (error) {
              console.log("get contacts error : ", error);
              reject(error);
            } else {
              console.log("contacts data : ", contacts);
              this.setResource({
                resource: "tagContacts",
                value: contacts,
              });
              resolve(contacts);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
